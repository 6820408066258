// This file was created to remove changes from mgbw\module_pagedesigner\cartridge\static\default\css\layout.css
// layout.css rewrite global styles and crush(because it has another margin/padding) global elements like footer and header

@import "../../../../ba_gp_storefront_core/cartridge/scss/default/mixin/breakpoint";
@import "../../../../ba_gp_storefront_core/cartridge/scss/default/variables";
@import "../../../../ba_gp_storefront_core/cartridge/scss/default/blueacorn/var";

.region {
    position: relative;

    &::before {
        display: block;
        content: "";
        width: 100%;
    }

    .experience-component {
        position: relative;
    }
}

.experience-component {
    &.experience-layouts-3column {
        width: 100%;
    }
}

.one-row-two-column,
.one-row-three-column,
.one-row-four-column {
    &.column-line-separators {
        > .region {
            &:last-child::after {
                @include bp(min-width, $bp-medium) {
                    display: none;
                }
            }

            &::after {
                @include bp(max-width, $bp-medium) {
                    content: "";
                    display: block;
                    width: var(--width-line-separator);
                    margin: auto;
                    border-bottom: 1px solid $butterfly-ash;
                }

                @include bp(min-width, $bp-medium) {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: block;
                    height: 100%;
                    width: var(--width-line-separator-md);
                    border-right: 1px solid $butterfly-ash;
                }
            }
        }
    }
}
